import React, { useEffect } from 'react';
import FullWrapper from "../layouts/fullWrapper";
import { useRouter } from "next/router";
import useOrgs from "../hooks/useOrgs";
import requireUser from '../lib/requireUser';

const Home = (props) => {
  const router = useRouter();
  const orgs = useOrgs();

  useEffect(() => {
    const orgPath = router.query.p ?? '';
    delete router.query.p;
    const queryString = Object.keys(router.query)
      .reduce((prev, key, i) => `${prev}${i > 0 ? '&' : '?'}${key}=${router.query[key]}`, '')
    if (orgs.length > 0) {
      router.push(`/org/${orgs[0].shortId}/${orgPath}${queryString}`);
    }
  }, [orgs.length]);
  return (
    <React.Fragment>
      <FullWrapper headerColor='default' >

      </FullWrapper>
    </React.Fragment>
  )
}

export default requireUser(Home);